import React from 'react'
import { Analytics } from '@genoa/analytics'
import { INCOME_AMOUNT as content } from '@genoa/screen-content'
import { Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { Gap, Headline1, MoneyInput, PrimaryButton, SmallText } from '../../../../components'
import { BasePageLayout } from '../../../../layouts'

interface WhatsYourIncomeProps {
  incomeAmount: string
  onClickContinue: () => void
  updateIncomeAmount: (enteredAmount: string) => void
  onClickWhatCountsAsIncome: () => void
  analyticsScreenName: Analytics.Screens
  isLoading: boolean
}

export const WhatsYourIncome = (props: WhatsYourIncomeProps) => {
  return (
    <BasePageLayout analyticsScreenName={props.analyticsScreenName}>
      <Flex direction="column">
        <Gap size={'spacing_200'} />

        <Headline1>{content.HEADER}</Headline1>

        <Gap size={'spacing_100'} />

        <SmallText>
          <SmallText>{content.BODY}</SmallText>
          <SmallText fontWeight={'bold'}>{content.BODY_BOLD}</SmallText>
        </SmallText>

        <Gap size={'spacing_200'} />

        <SmallText fontWeight={'medium'}>{content.INPUT_LABEL}</SmallText>
        <Gap size={'spacing_25'} />
        <MoneyInput
          testID={'IncomeAmountField'}
          onTextChange={props.updateIncomeAmount}
          value={props.incomeAmount}
          placeholder={'0'}
        />

        <Gap size={'spacing_100'} />

        <WhatCountsAsIncomeText
          fontWeight={'bold'}
          onClick={props.onClickWhatCountsAsIncome}
          data-testid={'WhatCountsAsIncomeLink'}
        >
          {content.WHAT_COUNTS}
        </WhatCountsAsIncomeText>

        <Gap size={'spacing_400'} />

        <PrimaryButton
          disabled={props.incomeAmount === '' || !props.incomeAmount}
          processing={props.isLoading}
          onClick={props.onClickContinue}
          testID="WhatsYourIncomeContinueButton"
        >
          {content.CTA}
        </PrimaryButton>
      </Flex>
    </BasePageLayout>
  )
}

const WhatCountsAsIncomeText = styled(SmallText)`
  color: ${(props) => props.theme.colors.jewelPurple};
  cursor: pointer;
`
