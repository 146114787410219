import { UpdateChannelSubscriptionRequest } from '@genoa/domain'
import axios from 'axios'

import { resolveGrowthInfraRoute } from './resolve-growth-infra-route'

const getSubscriptionsRequestRoute = (email: string) => {
  const route = resolveGrowthInfraRoute('api/subscriptions')
  return `${route}?email=${encodeURIComponent(email)}`
}

export const updateSubscription = (token: string, email: string, update: UpdateChannelSubscriptionRequest) => {
  const route = getSubscriptionsRequestRoute(email)
  return axios.post(route, update, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    validateStatus: function (status: number) {
      return status < 500
    },
    timeout: 4000,
  })
}
