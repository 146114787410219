import { useEffect, useState } from 'react'
import { capitalizeFirstLetter } from '@genoa/utils'

import { useAuthState } from '../../../../../contexts'
import { useGetCards } from '../../../../../hooks/flex2/wallet'
import { useCustomerWalletState } from '../../../../../modules/flex2/use-state'
import { CardDetails } from '../../../../components/PaymentMethods/common'

export interface UseCardDetailsOptions {
  readonly capitalizeCardNetwork?: boolean
}

export const useCardDetails = (props: UseCardDetailsOptions) => {
  const { user } = useAuthState()
  const customerWalletState = useCustomerWalletState()
  const { getDefaultCard } = useGetCards({ componentName: 'UseCardDetails' })
  const [cardDetails, setCardDetails] = useState<CardDetails | undefined>(undefined)

  useEffect(() => {
    if (!customerWalletState.initialized) {
      getDefaultCard()
    }

    const defaultCard = customerWalletState.defaultCard
    const network = props.capitalizeCardNetwork ? capitalizeFirstLetter(defaultCard.brand) : defaultCard.brand

    setCardDetails({
      cardLastFourDigits: defaultCard.card_mask,
      cardType: defaultCard.card_type ?? '--',
      cardNetwork: network,
    })
  }, [customerWalletState.initialized])

  return cardDetails
}
