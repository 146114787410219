import { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'
import { OfferStates } from '@genoa/middle-end'
import { GENERIC_ERROR_MODAL_CONTENT as errorContent } from '@genoa/screen-content'
import { ContinueEvaluationResponse, EvaluationInstruction, useQueryError } from '@genoa/state-management'

import { useAuthState } from '../../../../../contexts'
import { usePollingRequest, useShowErrorMessageModal } from '../../../../../hooks'
import { useContinueEvaluationMutation } from '../../../../../modules/flexApi'
import { useAnalytics, useIterable, useLogger } from '../../../../../providers'
import {
  createUnderwritingApprovedEvent,
  createUnderwritingDeniedEvent,
} from '../../../../../providers/iterable/user-events'
import * as Routes from '../../../../../routing/constants'
import { WaitingOffer } from '../../soft-credit-check/WaitingOffer'

export const IncomeVerificationWaitingOfferContainer = () => {
  const analytics = useAnalytics()
  const iterable = useIterable()
  const navigate = useNavigate()
  const loggerV2 = useLogger('IncomeVerificationWaitingOfferContainer')
  const { user } = useAuthState()
  const [continueEvaluation, { error: continueEvaluationError }] = useContinueEvaluationMutation()
  const { showErrorMessage } = useShowErrorMessageModal()

  const handleRejected = () => {
    const dataFields = { denial_reason: 'Denied' }
    iterable.addEvent(createUnderwritingDeniedEvent({ dataFields }))
    navigate(Routes.Onboarding.UPSELL_ELIGIBILITY, { replace: true })
  }

  const handlePendingAccept = () => {
    iterable.addEvent(createUnderwritingApprovedEvent())
    navigate(Routes.Onboarding.CUSTOMIZE_SCHEDULE, { replace: true })
  }

  const handleManualReview = () => {
    navigate(Routes.Onboarding.INCOME_VERIFICATION_PROCESSING_OFFER)
  }

  const { startPolling: continueEvaluationPolling } = usePollingRequest<ContinueEvaluationResponse>(
    useCallback(async () => {
      if (user?.uid) {
        const response = await continueEvaluation({
          customerId: user.uid,
        }).unwrap()

        if (response?.data.instruction !== EvaluationInstruction.InProgress) {
          analytics.logEvent(Analytics.Events.IV_CONTINUE_EVALUATION_SUCCESS)
          return response
        } else {
          if (response?.data.income_verification_status === 'VENDOR_FAILURE') {
            analytics.logEvent(Analytics.Events.IV_CONTINUE_EVALUATION_FAILURE)
            showErrorMessage(errorContent.TITLE, errorContent.SUBTITLE, 'Close', () => {
              navigate(Routes.Onboarding.WHATS_YOUR_INCOME)
            })
          }
          return false
        }
      }
      return false
    }, [user?.uid]),
    30000,
    5000
  )

  useQueryError(continueEvaluationError, {
    onAllErrors() {
      loggerV2.error('Error polling Continue Evaluation')
      analytics.logEvent(Analytics.Events.IV_CONTINUE_EVALUATION_FAILURE)
    },
  })

  const handlePolling = async () => {
    const result = await continueEvaluationPolling()

    switch (result?.data?.instruction) {
      case EvaluationInstruction.EvaluationCompleted:
        if (result?.data?.risk_offer?.offer_state == OfferStates.PENDING_ACCEPT) {
          return handlePendingAccept()
        }

        if (result?.data?.risk_offer?.offer_state == OfferStates.REJECTED) {
          return handleRejected()
        }

        break
      case EvaluationInstruction.SystemOutageRetryLater:
      case EvaluationInstruction.ManualReview:
      default:
        return handleManualReview()
    }
  }

  useEffect(() => {
    handlePolling()
  }, [])

  return <WaitingOffer />
}
