import React, { useCallback } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'

import { useAnalytics } from '../../../providers'
import * as Routes from '../../../routing/constants'
import { MoveInLanding } from './MoveInLanding'

export const MoveInLandingContainer = () => {
  const analytics = useAnalytics()
  const navigate = useNavigate()
  const [query] = useSearchParams()
  const property = query.get('property')

  const navigateToOnboarding = useCallback(() => {
    analytics.logEvent(Analytics.Events.MOVE_IN_CTA_CLICKED)
    navigate(`${Routes.Onboarding.CREATE_ACCOUNT}?product=move-in`)
  }, [])

  return (
    <MoveInLanding
      analyticsScreenName={Analytics.Screens.MOVE_IN}
      navigateToOnboarding={navigateToOnboarding}
      property={property || undefined}
    />
  )
}
