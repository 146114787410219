import React from 'react'
import { Analytics } from '@genoa/analytics'
import { deepReplaceContent, INCOME_VERIFICATION_UPLOAD_PAYSTUBS as content } from '@genoa/screen-content'
import { Flex, ListItem, UnorderedList } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'

import { WarningIcon } from '../../../../assets'
import { ExtraSmallText, Gap, Headline1, PrimaryButton, SmallText } from '../../../../components'
import { BasePageLayout } from '../../../../layouts'

interface UploadPaystubsProps {
  pastThirtyDaysDate: string
  nextBpMonth?: string
  onClickContinue: () => void
  onClickConnectYourBankAccount: () => void
  isReady: boolean
}

export const UploadPaystubs = (props: UploadPaystubsProps) => {
  const theme = useTheme()

  return (
    <BasePageLayout analyticsScreenName={Analytics.Screens.INCOME_VERIFICATION_UPLOAD_PAYSTUBS}>
      <Gap size={'spacing_200'} />
      <Headline1>{content.HEADER}</Headline1>
      <Gap size={'spacing_100'} />
      <SmallText>{content.BODY}</SmallText>
      <Gap size={'spacing_200'} />
      <DocumentRequirementsWrapper>
        <ExtraSmallText color={theme.colors.dusk} fontWeight={'bold'}>
          {content.REQUIREMENTS_HEADER}
        </ExtraSmallText>
        <Gap size={'spacing_75'} />
        <UnorderedList>
          {content.REQUIREMENTS_BULLETS.map((item, index) => (
            <ListItem key={`DocumentRequirement-${index}`}>
              <ExtraSmallText color={theme.colors.dusk}>
                {deepReplaceContent(item, { pastThirtyDaysDate: props.pastThirtyDaysDate })}
              </ExtraSmallText>
            </ListItem>
          ))}
        </UnorderedList>
      </DocumentRequirementsWrapper>
      <Gap size={'spacing_200'} />
      {!props.nextBpMonth ? (
        <ProcessingWrapper>
          <SmallText fontWeight={'bold'}>{content.CALLOUT.HEADER}</SmallText>
          <ExtraSmallText>
            <ExtraSmallText>{content.CALLOUT.BODY}</ExtraSmallText>
            <ConnectYourBankAccountLink onClick={props.onClickConnectYourBankAccount}>
              {content.CALLOUT.BODY_BOLD}
            </ConnectYourBankAccountLink>
          </ExtraSmallText>
        </ProcessingWrapper>
      ) : (
        <ProcessingWrapper>
          <DisclaimerWrapper>
            <WarningIcon />
            <DisclaimerHeader>
              {deepReplaceContent(content.DISCLAIMER.HEADER, { nextBpMonth: props.nextBpMonth })}
            </DisclaimerHeader>
          </DisclaimerWrapper>
          <Gap size={'spacing_100'} />
          <ExtraSmallText>
            <ExtraSmallText>{content.DISCLAIMER.BODY}</ExtraSmallText>
            <ConnectYourBankAccountLink onClick={props.onClickConnectYourBankAccount}>
              {content.DISCLAIMER.BODY_BOLD}
            </ConnectYourBankAccountLink>
          </ExtraSmallText>
        </ProcessingWrapper>
      )}
      <Gap size={'spacing_200'} />
      <PrimaryButton disabled={!props.isReady} onClick={props.onClickContinue} testID="UploadPaystubsContinueButton">
        {content.CTA}
      </PrimaryButton>
    </BasePageLayout>
  )
}

const DocumentRequirementsWrapper = styled(ExtraSmallText)`
  color: ${(props) => props.theme.colors.dusk};
`

const ProcessingWrapper = styled(Flex)`
  flex-direction: column;
  padding: ${(props) => props.theme.fixedSizes.spacing_100};
  background-color: ${(props) => props.theme.colors.cloud};
  border-radius: ${(props) => props.theme.radii.lg};
`

const ConnectYourBankAccountLink = styled(ExtraSmallText)`
  cursor: pointer;
  color: ${(props) => props.theme.colors.jewelPurple};
  font-weight: bold;
`

const DisclaimerWrapper = styled(Flex)`
  flex-direction: row;
  align-content: space-between;
  align-items: center;
`

const DisclaimerHeader = styled(SmallText)`
  padding-left: ${(props) => props.theme.fixedSizes.spacing_100};
  font-weight: bold;
`
