import { useEffect, useState } from 'react'
import { SetupAutopilotAction } from '@genoa/domain'
import { EMBED_AUTOPAY } from '@genoa/screen-content'
import { GeneralApiErrorCodes, useQueryError } from '@genoa/state-management'
import { Flex, Switch } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { useAuthState } from '../../../contexts'
import { useAutopilotEligibility, useDefaultErrorModals } from '../../../hooks'
import { useAutopilotSetupMutation } from '../../../modules/flexApi'
import { ShadowedFrame } from '../Frames'
import { SmallText, Text } from '../Typography'

interface Props {
  setIsLoadingToggleAutopay?: (isLoadingToggleAutopay: boolean) => void
}

export const AutopayToggle = ({ setIsLoadingToggleAutopay = () => {} }: Props) => {
  const { uid } = useAuthState()
  const { displayTooManyTriesModal, displayDefaultErrorModal } = useDefaultErrorModals()
  const [autopilotSetup, { isLoading, error }] = useAutopilotSetupMutation()
  const { isLoadingEligibility, isUserAutopayEnabled, refetchEligibility } = useAutopilotEligibility()
  const [autopilotStatus, setAutopilotStatus] = useState<SetupAutopilotAction>(SetupAutopilotAction.EPISODIC_ON)

  const getToggledStatus = () =>
    autopilotStatus === SetupAutopilotAction.AUTOPAY_ON
      ? SetupAutopilotAction.EPISODIC_ON
      : SetupAutopilotAction.AUTOPAY_ON

  const setAutopilot = async () => {
    if (!isLoading) {
      const status = getToggledStatus()
      setAutopilotStatus(status)
      await autopilotSetup({ customerId: uid!, status })
      await refetchEligibility()
    }
  }

  useEffect(() => {
    setIsLoadingToggleAutopay(isLoading)
  }, [isLoading])

  useQueryError(error, {
    onFlexApiError({ data: { error } }) {
      if (error.code === GeneralApiErrorCodes.RATE_LIMIT) {
        setAutopilotStatus(getToggledStatus())
        displayTooManyTriesModal()
        return true
      }
    },
    onAllErrors() {
      setAutopilotStatus(getToggledStatus())
      displayDefaultErrorModal()
    },
  })

  useEffect(() => {
    if (!isLoadingEligibility) {
      setAutopilotStatus(isUserAutopayEnabled ? SetupAutopilotAction.AUTOPAY_ON : SetupAutopilotAction.EPISODIC_ON)
    }
  }, [isLoadingEligibility, isUserAutopayEnabled])

  const bodyText =
    autopilotStatus === SetupAutopilotAction.AUTOPAY_ON ? EMBED_AUTOPAY.BODY_AUTOPAY : EMBED_AUTOPAY.BODY_EPISODIC

  return (
    <Frame>
      <FlexText>
        <Text fontWeight="bold">{EMBED_AUTOPAY.HEADER}</Text>
        <SmallTextBody>{bodyText}</SmallTextBody>
      </FlexText>
      <Flex>
        <Switch
          colorScheme="purple"
          isChecked={autopilotStatus === SetupAutopilotAction.AUTOPAY_ON}
          onChange={setAutopilot}
          size="lg"
        />
      </Flex>
    </Frame>
  )
}

const Frame = styled(ShadowedFrame)`
  align-items: center;
  flex-direction: row;
`

const FlexText = styled(Flex)`
  flex-direction: column;
  margin-right: ${({ theme }) => theme.fixedSizes.spacing_100};
`

const SmallTextBody = styled(SmallText)`
  color: ${({ theme }) => theme.colors.dusk};
`
