import { useEffect, useReducer, useState } from 'react'
import { UpdateChannelSubscriptionRequest } from '@genoa/domain'
import { AmplitudeFeatureFlag } from '@genoa/experiments'

import { useAuthState } from '../../contexts'
import { useRetryLock } from '../../hooks'
import { useAmplitudeFeatureFlag } from '../../hooks/use-amplitude-feature-flag'
import { useUpdateIterableSubscriptionsMutation } from '../../modules/flexApi'
import { updateSubscription as updateGrowthSubscription } from '../growth'
import { logger } from '../logger'
import { useUserAccount } from '../user-account'

interface ResetAction {
  readonly type: 'reset'
}

interface AddSubscriptionAction {
  readonly type: 'add'
  readonly update: UpdateChannelSubscriptionRequest
}

interface UpdateAction {
  readonly type: 'update'
  readonly updates: readonly UpdateChannelSubscriptionRequest[]
}

type DeferredEventsAction = ResetAction | AddSubscriptionAction | UpdateAction

// how to do events one at a time tho?
const deferredEventsReducer = (
  state: readonly UpdateChannelSubscriptionRequest[],
  action: DeferredEventsAction
): readonly UpdateChannelSubscriptionRequest[] => {
  switch (action.type) {
    case 'add':
      return state.concat([action.update])
    case 'update':
      return action.updates
    case 'reset':
    default:
      return []
  }
}

export interface UseDeferredSubscriptionsOptions {
  readonly locked: boolean
}

export const useDeferredSubscriptions = ({ locked }: UseDeferredSubscriptionsOptions) => {
  const [inflightUpdate, setInflightUpdate] = useState<UpdateChannelSubscriptionRequest | undefined>(undefined)
  const [updates, dispatch] = useReducer(deferredEventsReducer, [])
  const { lock: retryLock, incrementFailures, resetFailures } = useRetryLock()
  const { token } = useAuthState()
  const { userAccount } = useUserAccount()

  const { enabled: ocIterableEnabled } = useAmplitudeFeatureFlag(AmplitudeFeatureFlag.OCIterableAPI)

  const [updateOCSubscription] = useUpdateIterableSubscriptionsMutation()

  const reset = () => {
    dispatch({ type: 'reset' })
  }

  useEffect(() => {
    if (!locked && !inflightUpdate && updates.length > 0) {
      const [update, ...otherUpdates] = updates
      setInflightUpdate(update)
      dispatch({ type: 'update', updates: otherUpdates })
    }
  }, [locked, retryLock, updates, inflightUpdate])

  useEffect(() => {
    if (
      locked ||
      retryLock ||
      !inflightUpdate ||
      (ocIterableEnabled && !userAccount.email) ||
      (!ocIterableEnabled && (!token || !userAccount.email))
    ) {
      return
    }

    const handleSuccess = () => {
      logger.log(
        'use-deferred-subscriptions',
        `set ${inflightUpdate.channel} subscription to ${inflightUpdate.enabled} successfully`
      )
      setInflightUpdate(undefined)
      resetFailures()
    }

    const handleError = (message: string) => {
      logger.warn(
        'use-deferred-subscriptions',
        `set ${inflightUpdate.channel} subscription to ${inflightUpdate.enabled} failed: ${message}`
      )
      incrementFailures()
    }

    if (ocIterableEnabled) {
      updateOCSubscription({ email: userAccount.email!, ...inflightUpdate })
        .unwrap()
        .then((response) => {
          if (response.error) {
            handleError(String(response.error))
          } else {
            handleSuccess()
          }
        })
        .catch(handleError)
    } else {
      updateGrowthSubscription(token!, userAccount.email!, inflightUpdate)
        .then(({ status }) => {
          if (status === 200) {
            handleSuccess()
          }
        })
        .catch(handleError)
    }
  }, [locked, inflightUpdate, retryLock, token, userAccount.email, updateOCSubscription, incrementFailures])

  return {
    addUpdate: (update: UpdateChannelSubscriptionRequest) => dispatch({ type: 'add', update }),
    reset,
  }
}
