import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'
import { SLCType } from '@genoa/domain'
import { Box, Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { useGetCards } from '../../../../../hooks/flex2/wallet'
import { useSecureLineOfCredit } from '../../../../../hooks/secure-line-of-credit'
import { logger } from '../../../../../providers'
import * as Routes from '../../../../../routing/constants'
import { SpinnerLoading } from '../../../../components/SpinnerLoading/SpinnerLoading'
import { BasePageLayout } from '../../../../layouts'

export const CardStageContainer = () => {
  const { getDefaultCard } = useGetCards({ componentName: 'CardStageContainer' })
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const { isLoadingSLC, slcType } = useSecureLineOfCredit()

  useEffect(() => {
    if (!isLoadingSLC) {
      setLoading(true)
      getDefaultCard()
        .then((response) => {
          setLoading(false)
          const showConfirmCard = response?.default
          if (!showConfirmCard && slcType === SLCType.CLASSIC) {
            navigate(Routes.Onboarding.CARD_REGISTER, {
              state: {
                analyticsScreenName: Analytics.Screens.ADD_CARD_ONBOARDING_ADD_CARD,
                nextRoute: Routes.Onboarding.OFFER_DETAILS,
              },
            })
          } else if (showConfirmCard) {
            navigate(Routes.Onboarding.CARD_CONFIRM)
          } else {
            navigate(Routes.Onboarding.CARD_CONNECT)
          }
        })
        .catch((error: any) => {
          setLoading(false)
          logger.error('CardStageContainer - getDefaultCard', `Could not get default card, reason: ${error?.message}`)
          navigate(Routes.Onboarding.CARD_CONNECT)
        })
    }
  }, [isLoadingSLC, slcType])

  return (
    <BasePageLayout>
      <Container flex={1} flexDirection="column" px="lg" alignItems={'center'}>
        <Box marginTop={'192px'}>{loading && <SpinnerLoading />}</Box>
      </Container>
    </BasePageLayout>
  )
}

const Container = styled(Flex)`
  height: 100%;
`
