import { Box, Stat, StatLabel, StatNumber } from '@chakra-ui/react'

import { Config } from '../../providers'
import { BasePageLayout } from '../layouts'

function KeyVal(props: { label: React.ReactNode; value: React.ReactNode }) {
  return (
    <Stat>
      <StatLabel color="gray">{props.label}</StatLabel>
      <StatNumber>{props.value}</StatNumber>
    </Stat>
  )
}

export function HealthcheckPage() {
  return (
    <BasePageLayout hideHelpButton>
      <Box border="1px" borderRadius="2xl" borderColor="lightgray" p="5" justifyContent="center" display="flex" mt="5">
        <KeyVal label="Environment" value={Config.ENVIRONMENT.toUpperCase()} />
        <KeyVal label="Version" value={import.meta.env.APP_VERSION} />
        <KeyVal label="SHA" value={import.meta.env.VITE_COMMIT_SHA?.slice(0, 7) || '--'} />
      </Box>
    </BasePageLayout>
  )
}
