import { Route, Routes as RouterRoutes } from 'react-router-dom'
import { SLCType } from '@genoa/domain'

import { useSecureLineOfCredit } from '../../hooks'
import { CreditBuilderUpsellNotApprovedContainer } from '../../views/pages/common/credit-builder/upsell-not-approved'
import { P2PWaitlist } from '../../views/pages/common/property-linking/P2PWaitlist'
import { ContactSupportContainer } from '../../views/pages/contact-support/ContactSupportContainer'
import { NotApprovedContainer } from '../../views/pages/not-approved/NotApprovedContainer'
import { UpsellEligibilityContainer } from '../../views/pages/not-approved/UpsellEligibilityContainer'
import { OnboardingConfirmAccountContainer } from '../../views/pages/onboarding/account-creation/OnboardingConfirmAccountContainer'
import { OnboardingCreateAccountContainer } from '../../views/pages/onboarding/account-creation/OnboardingCreateAccountContainer'
import {
  OnboardingCreditBuilderUpsellContainer,
  OnboardingHowYouUseFlexContainer,
} from '../../views/pages/onboarding/credit-builder'
import { OnboardingCreditBuilderValuePropContainer } from '../../views/pages/onboarding/credit-builder/OnboardingCreditBuilderValuePropContainer'
import { IncomeVerificationProcessingOffer } from '../../views/pages/onboarding/income-verification/IncomeVerificationProcessingOffer'
import { UploadPaystubsContainer } from '../../views/pages/onboarding/income-verification/upload-paystubs/UploadPaystubsContainer'
import { VerifyYourIncomeContainer } from '../../views/pages/onboarding/income-verification/verify-income/VerifyYourIncomeContainer'
import { IncomeVerificationWaitingOfferContainer } from '../../views/pages/onboarding/income-verification/waiting-offer/IncomeVerificationWaitingOfferContainer'
import { WhatsYourIncomeContainer } from '../../views/pages/onboarding/income-verification/whats-your-income/WhatsYourIncomeContainer'
import { CardStageContainer } from '../../views/pages/onboarding/payment-method/card/CardStageContainer'
import { V2ConfirmCardContainer } from '../../views/pages/onboarding/payment-method/card/confirm-card/V2ConfirmCardContainer'
import { V2ConnectCardContainer } from '../../views/pages/onboarding/payment-method/card/connect-card/V2ConnectCardContainer'
import { RegisterCardContainer } from '../../views/pages/onboarding/payment-method/card/register-card/RegisterCardContainer'
import { UpdateCardBillingAddressContainer } from '../../views/pages/onboarding/payment-method/card/update-card-billing-address/UpdateCardBillingAddressContainer'
import { OnboardingAddressSelectionContainer } from '../../views/pages/onboarding/property-linking/OnboardingAddressSelectionContainer'
import { OnboardingDirectIntegrationConfirmAddressContainer } from '../../views/pages/onboarding/property-linking/OnboardingDirectIntegrationConfirmAddressContainer'
import { OnboardingDirectIntegrationUnitSelectionContainer } from '../../views/pages/onboarding/property-linking/OnboardingDirectIntegrationUnitSelectionContainer'
import { OnboardingFlexAnywhereHowFlexWorksContainer } from '../../views/pages/onboarding/property-linking/OnboardingFlexAnywhereHowFlexWorksContainer'
import { OnboardingFlexAnywhereHowYouPayContainer } from '../../views/pages/onboarding/property-linking/OnboardingFlexAnywhereHowYouPayContainer'
import { OnboardingFlexAnywhereWaitlistContainer } from '../../views/pages/onboarding/property-linking/OnboardingFlexAnywhereWaitlistContainer'
import { OnboardingFlexAnywhereWhereYouPayContainer } from '../../views/pages/onboarding/property-linking/OnboardingFlexAnywhereWhereYouPayContainer'
import { OnboardingPortalAddressEntryContainer } from '../../views/pages/onboarding/property-linking/OnboardingPortalAddressEntryContainer'
import { OnboardingRentAmountContainer } from '../../views/pages/onboarding/property-linking/OnboardingRentAmountContainer'
import { OnboardingRentPortalDetailsContainer } from '../../views/pages/onboarding/property-linking/OnboardingRentPortalDetailsContainer'
import { OnboardingRentPortalSelectionContainer } from '../../views/pages/onboarding/property-linking/OnboardingRentPortalSelectionContainer'
import { V2OnboardingRentPortalSignInContainer } from '../../views/pages/onboarding/property-linking/V2OnboardingRentPortalSignInContainer'
import { OnboardingResumeOnboardingContainer } from '../../views/pages/onboarding/resume-onboarding/OnboardingResumeOnboardingContainer'
import * as Routes from '../constants'
import { getRouteSlugs } from '../utils'
import { OnboardingRoot } from './OnboardingRoot'
import { SCHEDULE_SETUP_CREDIT_BUILDER_ROUTES } from './ScheduleSetupCreditBuilderRoutes'
import { SCHEDULE_SETUP_ROUTES } from './ScheduleSetupRoutes'
import { SLC_CLASSIC_ONBOARDING_ROUTES } from './SLCClassicRoutes'

const routeSlugs = getRouteSlugs(Routes.App.ONBOARDING, Routes.Onboarding)

const SCREENS_MAP: Record<SLCType, JSX.Element> = {
  [SLCType.CREDIT_BUILDER]: SCHEDULE_SETUP_CREDIT_BUILDER_ROUTES,
  [SLCType.FLEX_ANYWHERE]: SLC_CLASSIC_ONBOARDING_ROUTES,
  [SLCType.CLASSIC]: SLC_CLASSIC_ONBOARDING_ROUTES,
}

export function OnboardingRoutes() {
  const { slcType } = useSecureLineOfCredit()

  return (
    <RouterRoutes>
      <Route index Component={OnboardingRoot} />
      <Route path={routeSlugs.CREATE_ACCOUNT} Component={OnboardingCreateAccountContainer} />
      <Route path={routeSlugs.CONFIRM_ACCOUNT} Component={OnboardingConfirmAccountContainer} />
      <Route path={routeSlugs.CREDIT_BUILDER_VALUE_PROP} Component={OnboardingCreditBuilderValuePropContainer} />
      <Route path={routeSlugs.RESUME_ONBOARDING} Component={OnboardingResumeOnboardingContainer} />
      <Route path={routeSlugs.ADDRESS} Component={OnboardingAddressSelectionContainer} />
      <Route path={routeSlugs.PORTAL_ADDRESS_ENTRY} Component={OnboardingPortalAddressEntryContainer} />
      <Route path={routeSlugs.RENT_PORTAL_DETAILS} Component={OnboardingRentPortalDetailsContainer} />
      <Route path={routeSlugs.RENT_PORTAL_SELECTION} Component={OnboardingRentPortalSelectionContainer} />
      <Route path={routeSlugs.P2P_WAITLIST} Component={P2PWaitlist} />
      <Route path={routeSlugs.FLEX_ANYWHERE_WHERE_YOU_PAY} Component={OnboardingFlexAnywhereWhereYouPayContainer} />
      <Route path={routeSlugs.FLEX_ANYWHERE_HOW_YOU_PAY} Component={OnboardingFlexAnywhereHowYouPayContainer} />
      <Route path={routeSlugs.FLEX_ANYWHERE_WAITLIST} Component={OnboardingFlexAnywhereWaitlistContainer} />
      <Route path={routeSlugs.FLEX_ANYWHERE_HOW_FLEX_WORKS} Component={OnboardingFlexAnywhereHowFlexWorksContainer} />
      <Route path={routeSlugs.RENT_PORTAL_SIGNIN} Component={V2OnboardingRentPortalSignInContainer} />
      <Route path={routeSlugs.DIRECT_INTEGRATION_UNIT} Component={OnboardingDirectIntegrationUnitSelectionContainer} />
      <Route
        path={routeSlugs.DIRECT_INTEGRATION_CONFIRMATION}
        Component={OnboardingDirectIntegrationConfirmAddressContainer}
      />
      <Route path={routeSlugs.RENT_AMOUNT} Component={OnboardingRentAmountContainer} />
      <Route path={routeSlugs.CARD_STAGE_SELECTION} Component={CardStageContainer} />
      <Route path={routeSlugs.CARD_CONNECT} Component={V2ConnectCardContainer} />
      <Route path={routeSlugs.CARD_CONFIRM} Component={V2ConfirmCardContainer} />
      <Route path={routeSlugs.CARD_REGISTER} Component={RegisterCardContainer}>
        <Route path={routeSlugs.CARD_UPDATE_BILLING_ADDRESS} Component={UpdateCardBillingAddressContainer} />
      </Route>

      <Route path={routeSlugs.CREDIT_BUILDER_UPSELL} Component={OnboardingCreditBuilderUpsellContainer} />
      <Route path={routeSlugs.CREDIT_BUILDER_UPSELL_NOT_APPROVED} Component={CreditBuilderUpsellNotApprovedContainer} />

      <Route path={routeSlugs.UPSELL_ELIGIBILITY} Component={UpsellEligibilityContainer} />
      <Route path={routeSlugs.WHATS_YOUR_INCOME} Component={WhatsYourIncomeContainer} />
      <Route path={routeSlugs.VERIFY_YOUR_INCOME} Component={VerifyYourIncomeContainer} />
      <Route path={routeSlugs.INCOME_VERIFICATION_WAITING_OFFER} Component={IncomeVerificationWaitingOfferContainer} />
      <Route path={routeSlugs.INCOME_VERIFICATION_PROCESSING_OFFER} Component={IncomeVerificationProcessingOffer} />
      <Route path={routeSlugs.UPLOAD_PAYSTUBS} Component={UploadPaystubsContainer} />
      <Route path={routeSlugs.NOT_APPROVED} Component={NotApprovedContainer} />
      <Route path={routeSlugs.CONTACT_SUPPORT} Component={ContactSupportContainer} />
      <Route path={routeSlugs.SLC_PRODUCT_SELECTION} Component={OnboardingHowYouUseFlexContainer} />
      {SCREENS_MAP[slcType!] || SCHEDULE_SETUP_ROUTES}
    </RouterRoutes>
  )
}
