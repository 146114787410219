import { OnboardingInAppIncomeVerificationExperiment } from '@genoa/experiments'

import { useExperimentVariant } from '../use-experiment-variant'

export const useOnboardingInAppIncomeVerificationExperiment = () => {
  const OnboardingInAppIncomeVerificationExperimentVariant = useExperimentVariant(
    OnboardingInAppIncomeVerificationExperiment
  )

  const isOnboardingInAppIncomeVerificationEnabled =
    OnboardingInAppIncomeVerificationExperimentVariant ===
    OnboardingInAppIncomeVerificationExperiment.variantValues.Treatment

  return {
    isOnboardingInAppIncomeVerificationEnabled,
  }
}
