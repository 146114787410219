import { GenerateJWTPayload } from '@iterable/web-sdk'
import axios from 'axios'

import { resolveGrowthInfraRoute } from './resolve-growth-infra-route'

// platform for backend secret switching
const platform = 'web'

export const getIterableJWT = async (token: string, request: GenerateJWTPayload) => {
  const route = resolveGrowthInfraRoute('api/iterable-jwt')
  return axios.post<string>(
    route,
    { ...request, platform },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      validateStatus: function (status: number) {
        return status < 400
      },
      timeout: 4000,
    }
  )
}
