import { Analytics } from '@genoa/analytics'
import { MOVE_IN } from '@genoa/screen-content'
import { Box, Stack } from '@chakra-ui/react'

import { SpotKeys } from '../../assets'
import { Headline1, PrimaryButton, SmallText } from '../../components'
import { BasePageLayout } from '../../layouts'

type MoveInLandingProps = {
  property?: string
  navigateToOnboarding: () => unknown
  analyticsScreenName: Analytics.Screens
}

export const MoveInLanding = (props: MoveInLandingProps) => {
  return (
    <BasePageLayout
      centered
      analyticsScreenName={props.analyticsScreenName}
      coBrandingProperty={props.property || undefined}
    >
      <Stack spacing="5" alignItems="center">
        <SpotKeys />
        <Headline1 textAlign="center">{MOVE_IN.TITLE}</Headline1>
        <SmallText color="gray.600" textAlign="center">
          {MOVE_IN.SUBTITLE}
        </SmallText>
        <Box width="xs">
          <PrimaryButton onClick={props.navigateToOnboarding}>{MOVE_IN.CTA}</PrimaryButton>
        </Box>
      </Stack>
    </BasePageLayout>
  )
}
