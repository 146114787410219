import { Analytics } from '@genoa/analytics'
import { CO_WAITING_OFFER, WAITING_OFFER } from '@genoa/screen-content'
import { Box, Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { useContentOverhaul } from '../../../../hooks'
import { HeroSocks as HangTightImage } from '../../../assets'
import { SpinnerLoading } from '../../../components'
import { Headline1, SmallText, Text } from '../../../components'
import { BasePageLayout } from '../../../layouts'

export const WaitingOffer = () => {
  const { onboardingEnabled } = useContentOverhaul()
  const content = onboardingEnabled ? CO_WAITING_OFFER : WAITING_OFFER

  return (
    <BasePageLayout analyticsScreenName={Analytics.Screens.ONBOARDING_WAITING_OFFER}>
      <Box minH={'60px'} />

      <Flex align="center" justify="center">
        <HangTightImage />
      </Flex>

      <Box minH={'18px'} />

      <Container flex={1} flexDirection="column" px="lg" alignItems={'center'}>
        <Headline1>{content.HEADER}</Headline1>

        <Box minH={'24px'} />

        <Text textAlign="center" data-testid="WaitingOfferBody">
          {content.BODY}
        </Text>

        <Box minH={'24px'} />

        <SmallText textAlign="center" fontWeight={'bold'} data-testid="WaitingOfferBodyBold">
          {content.BODY_BOLD}
        </SmallText>

        <Box minH={'64px'} />

        <Box>
          <SpinnerLoading />
        </Box>
      </Container>
    </BasePageLayout>
  )
}

const Container = styled(Flex)`
  height: 100%;
`
