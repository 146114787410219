import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'
import {
  deepReplaceContent,
  INCOME_BELOW_THRESHOLD as incomeBelowThresholdModal,
  INCOME_VERIFICATION_AMOUNT_LOCAL_STORAGE,
  WHAT_COUNTS_AS_INCOME as whatCountsAsIncomeModal,
} from '@genoa/screen-content'
import { GENERIC_ERROR_MODAL_CONTENT as errorContent } from '@genoa/screen-content'
import { useQueryError } from '@genoa/state-management'
import { toFormattedDollars } from '@genoa/utils'
import { Box, Flex, ListItem, UnorderedList } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { useAuthState, useModal } from '../../../../../contexts'
import { useShowErrorMessageModal } from '../../../../../hooks'
import { useOfferState } from '../../../../../modules/flex2/use-state'
import { useSelfReportIncomeMutation } from '../../../../../modules/flexApi'
import { useAnalytics, useIterable } from '../../../../../providers'
import * as Routes from '../../../../../routing/constants'
import { Gap, SmallText, Text } from '../../../../components'
import { WhatsYourIncome } from './WhatsYourIncome'

export const WhatsYourIncomeContainer = () => {
  const { user } = useAuthState()
  const analytics = useAnalytics()
  const iterable = useIterable()
  const modal = useModal()
  const navigate = useNavigate()
  const offerState = useOfferState()
  const { showErrorMessage } = useShowErrorMessageModal()

  const [incomeDollarsString, setIncomeDollarsString] = useState<string>('')
  const [incomeDollars, setIncomeDollars] = useState<number>(0)

  const [selfReportIncome, { isLoading, error: selfReportIncomeError }] = useSelfReportIncomeMutation()

  const handleUpdateIncomeAmount = (enteredAmount: string) => {
    setIncomeDollarsString(enteredAmount)
    setIncomeDollars(Number(enteredAmount))
  }

  const handleClickWhatCountsAsIncome = () => {
    analytics.logEvent(Analytics.Events.INCOME_AMOUNT_WHAT_COUNTS_MODAL)

    modal.show({
      title: whatCountsAsIncomeModal.HEADER,
      cta: whatCountsAsIncomeModal.CTA,
      hasCloseButton: false,
      onCTAclicked: () => modal.close(),
      render: () => (
        <Flex flexDirection={'column'} textAlign={'left'} data-testid={'WhatCountsAsIncomeModal'}>
          <SmallText>{whatCountsAsIncomeModal.BODY_1}</SmallText>

          <Gap size={'spacing_100'} />
          <Box>
            <UnorderedList>
              {whatCountsAsIncomeModal.BULLETS.map((item, index) => (
                <ListItem key={`whatsYourIncomeListItem-${index}`}>
                  <SmallText>{item}</SmallText>
                </ListItem>
              ))}
            </UnorderedList>
          </Box>
          <Gap size={'spacing_100'} />

          <SmallText>{whatCountsAsIncomeModal.BODY_2}</SmallText>
        </Flex>
      ),
    })
  }

  const handleClickContinue = () => {
    analytics.logEvent(Analytics.Events.INCOME_AMOUNT_CTA_CLICKED)

    if (incomeDollars < 10000) {
      handleIncomeEnteredLessThan10000()
    } else {
      handleConfirmAmount()
    }
  }

  const handleConfirmAmount = async () => {
    const response = await selfReportIncome({
      customerId: user?.uid!,
      verificationId: offerState.offer.onboarding_income_verification_id!,
      annual_income_dollars: incomeDollars,
    }).unwrap()

    analytics.logEvent(Analytics.Events.IV_SELF_REPORT_INCOME_SUCCESS)
    if (response.data?.need_further_verification) {
      localStorage.setItem(INCOME_VERIFICATION_AMOUNT_LOCAL_STORAGE, incomeDollarsString)
      navigate(Routes.Onboarding.VERIFY_YOUR_INCOME)
    } else {
      navigate(Routes.Onboarding.INCOME_VERIFICATION_WAITING_OFFER)
    }
  }

  useQueryError(selfReportIncomeError, {
    onAllErrors() {
      analytics.logEvent(Analytics.Events.IV_SELF_REPORT_INCOME_FAILURE)

      showErrorMessage(errorContent.TITLE, errorContent.SUBTITLE)
    },
  })

  const handleIncomeEnteredLessThan10000 = () => {
    analytics.logEvent(Analytics.Events.INCOME_AMOUNT_BELOW_THRESHOLD_MODAL)

    const header = deepReplaceContent(incomeBelowThresholdModal.HEADER, {
      incomeAmount: toFormattedDollars(incomeDollars * 100),
    })

    modal.show({
      title: header,
      cta: incomeBelowThresholdModal.CTA,
      hasCloseButton: false,
      onCTAclicked: handleConfirmAmount,
      render: () => (
        <Flex flexDirection={'column'} textAlign={'left'} data-testid={'IncomeBelowThresholdModal'}>
          <SmallText>{incomeBelowThresholdModal.BODY}</SmallText>
          <Gap size={'spacing_200'} />
          <GoBackText onClick={() => modal.close()} fontWeight={'bold'}>
            {incomeBelowThresholdModal.CANCEL}
          </GoBackText>
        </Flex>
      ),
    })
  }

  return (
    <WhatsYourIncome
      analyticsScreenName={Analytics.Screens.INCOME_AMOUNT}
      incomeAmount={incomeDollarsString}
      onClickContinue={handleClickContinue}
      updateIncomeAmount={handleUpdateIncomeAmount}
      onClickWhatCountsAsIncome={handleClickWhatCountsAsIncome}
      isLoading={isLoading}
    />
  )
}

const GoBackText = styled(Text)`
  cursor: pointer;
  color: ${(props) => props.theme.colors.jewelPurple};
  text-align: center;
  font-size: ${(props) => props.theme.fontSizes.button};
`
